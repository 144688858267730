.button.flat {
  background-image: none;
  border-width: 0;
  box-shadow: 0 1px 2px rgba($gray-darkest, 0.3); // flat but raised ..
  text-shadow: none;
  font-weight: normal;
}

.button.flat:hover {
  background-image: none;
}

.button.flat.purple {
  background-color: $primary-purple;
  color: $gray-lightest;
}

.button.flat.purple.selected,
.button.flat.purple:focus,
.button.flat.purple:hover,
.button.flat.purple:active {
  background-color: $secondary-purple;
}

.button.flat.orange,
.button.flat.orange:focus {
  background: map-deep-get($brand, 'ter', 'dark') !important;
  border: 1px map-deep-get($brand, 'ter', 'dark') solid !important;
  color: $gray-lightest;
}

.button.flat.gold {
  background-color: map-deep-get($brandacc, 'ter', 'light', 'bg');
  border: 1px solid map-deep-get($brandacc, 'ter', 'light', 'bg');
  color: $gray-darkest;
}

.button.flat.red,
.button.flat.red:focus {
  background-color: $neg-red;
  color: $gray-lightest;
}

.button.flat.green,
.button.flat.green:focus {
  background-color: $inline-success;
  color: $gray-darkest;
}

.button.flat.greenwhite,
.button.flat.greenwhite:focus {
  background-color: $inline-success;
  color: $gray-lightest;
}

.button.flat.green.moreinfo,
.button.flat.green.moreinfo:focus {
  background-color: $inline-success;
  color: $gray-darkest;
  letter-spacing: 0;
  font-size: 1em;
  max-width: 15em;
}

.button.flat.gray,
.button.flat.gray:focus {
  background-color: #666;
  color: $gray-lightest;
}
