// TABtouch custom

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

////
/// String to number converter
/// @author Hugo Giraudel
/// @access private
////


///
/// Casts a string into a number
///
/// @param {String | Number} $value - Value to be parsed
///
/// @return {Number}
///
@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i)); }

    @if $character == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }

  @return if($minus, -$result, $result);
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/// Cast a number into a string
@function to-string($value) {
  @return inspect($value);
}

// Font sizes
$base-font-size: 10px;
$rem-ratio: calc($base-font-size / 1rem);

// Colours
$primary-purple: #512d6d;
$primary-purple-start: #63427c;
$primary-red: #cb333b;
$primary-red-start: #d35158;
$primary-orange: #e87722;
$primary-orange-start: #ee9959;

$secondary-purple: #8246af;
$secondary-purple-start: #9b6bbf;
$secondary-blue: #6787b7;
$secondary-blue-start: #8da5c9;
$secondary-blue-dark: #1d428a;
$secondary-blue-dark-start: #4a68a1;
$secondary-aqua: #008578;
$secondary-aqua-start: #40a49a;
$secondary-green: #b7bf10;
$secondary-green-start: #cdd258;
$secondary-orange: #f2a900;
$secondary-orange-start: #f5bf40;

$tertiary-yellow: #ffffe0;
$tertiary-green: #689f38;
$tertiary-green-start: #779e00; // For gradient

$pos-green: #689f38;
$pos-green-start: #afd04d; // For gradient
$neg-red: #cd0000;
$neg-red-start: #da4040; // For gradient
$caution-orange: #d5681d;
$caution-orange-start: #da8c40; // For gradient
$info-blue: #4f95dd;
$info-blue-start: #84b5e7; // For gradient

//== Fonts
//
//## Assigning them to a var so I don't have to keep typing them out.
$fonts: (
  pri: ('PFSquare', Arial, Helvetica, sans-serif),
  prithin: ('PFSquareThin', Arial, Helvetica, sans-serif),
  pristr: ('PFSquareBold', 'Arial Black', Arial, Helvetica, sans-serif),
  priem: ('PFSquareItalic', Arial, Helvetica, sans-serif),
  pristrem: ('PFSquareBoldItalic', 'Arial Black', Arial, Helvetica, sans-serif),
  sec: (Arial, Helvetica, sans-serif)
);

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-darkest: #000 !default;
$gray-darker: lighten(#000, 13.5%) !default; // #222
$gray-dark: lighten(#000, 20%) !default;   // #333
$gray: lighten(#000, 33.5%) !default; // #555
$gray-mid: lighten(#000, 50%) !default; // #808080
$gray-light: lighten(#000, 80%) !default;   // #ccc
$gray-lighter: lighten(#000, 93.5%) !default; // #eee
$gray-nearwhite: lighten(#000, 95.5%) !default; // #f4f4f4
$gray-lightest: #fff !default;

$brand-primary: #428bca !default;
$brand-success: #689f38 !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #cc0404 !default;

$inline-error: #d64748;
$inline-exclaim: #df7227;
$inline-info: #4f95dd;
$inline-success: #8dbb00;

$brand: (
  pri: (
    base: #512d6d,
    light: #8246af
  ),
  sec: (
    base: #cb333b
  ),
  ter: (
    base: #e87722,
    dark: #dc6d18,
    light: #f2a900
  ),
  qua: (
    base: #6787b7,
    dark: #1d428a
  ),
  qui: (
    base: #b7bf10,
    dark: #008578
  ),
  ui: (
    good: #689f38,
    fail: #cd0000,
    warn: #d5681d,
    info: #4f95dd,
    fob: #02b0ae
  ),
  'gray': (
    darkest: #000,
    darker: #222,
    dark: #333,
    base: #555,
    mid: #808080,
    light: #ccc,
    lighter: #eee,
    nearwhite: #f4f4f4,
    lightest: #fff
  ),
  fob:(
    base: #30878e
  ),
  tote:(
    base: #542d6b
  )
);

$brandacc: (
  pri: (

    base: (
      bg: map-deep-get($brand, 'pri', 'base'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'pri', 'base'), 5%),
      bghover: lighten(map-deep-get($brand, 'pri', 'base'), 5%)
    ),
    light: (
      bg: map-deep-get($brand, 'pri', 'light'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'pri', 'light'), 10%),
      bghover: lighten(map-deep-get($brand, 'pri', 'light'), 3%)
    )
  ),
  sec: (

    base: (
      bg: map-deep-get($brand, 'sec', 'base'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'sec', 'base'), 10%),
      bghover: lighten(map-deep-get($brand, 'sec', 'base'), 3%)
    )
  ),
  ter: (

    base: (
      bg: map-deep-get($brand, 'ter', 'base'),
      fg: darken(map-deep-get($brand, 'ter', 'base'), 51.5%),
      border: darken(map-deep-get($brand, 'ter', 'base'), 5%),
      bghover: lighten(map-deep-get($brand, 'ter', 'base'), 3%)
    ),
    dark: (
      bg: map-deep-get($brand, 'ter', 'dark'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'ter', 'dark'), 5%),
      bghover: lighten(map-deep-get($brand, 'ter', 'dark'), 3%)
    ),
    light: (
      bg: map-deep-get($brand, 'ter', 'light'),
      fg: darken(map-deep-get($brand, 'ter', 'light'), 37%),
      border: darken(map-deep-get($brand, 'ter', 'light'), 5%),
      bghover: lighten(map-deep-get($brand, 'ter', 'light'), 3%)
    )
  ),
  qua: (

    base: (
      bg: map-deep-get($brand, 'qua', 'base'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'qua', 'base'), 5%),
      bghover: lighten(map-deep-get($brand, 'qua', 'base'), 3%)
    ),
    dark: (
      bg: map-deep-get($brand, 'qua', 'dark'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'qua', 'dark'), 5%),
      bghover: lighten(map-deep-get($brand, 'qua', 'dark'), 3%)
    )
  ),
  qui: (

    base: (
      bg: map-deep-get($brand, 'qui', 'base'),
      fg: darken(map-deep-get($brand, 'qui', 'base'), 35%),
      border: darken(map-deep-get($brand, 'qui', 'base'), 5%),
      bghover: lighten(map-deep-get($brand, 'qui', 'base'), 3%)
    ),
    dark: (
      bg: map-deep-get($brand, 'qui', 'dark'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'qui', 'dark'), 5%),
      bghover: lighten(map-deep-get($brand, 'qui', 'dark'), 3%)
    )
  ),
  ui: (
    good: (
      bg: map-deep-get($brand, 'ui', 'good'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'ui', 'good'), 5%),
      bghover: lighten(map-deep-get($brand, 'ui', 'good'), 3%)
    ),
    fail: (
      bg: map-deep-get($brand, 'ui', 'fail'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'ui', 'fail'), 10%),
      bghover: lighten(map-deep-get($brand, 'ui', 'fail'), 3%)
    ),
    warn: (
      bg: map-deep-get($brand, 'ui', 'warn'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'ui', 'warn'), 5%),
      bghover: lighten(map-deep-get($brand, 'ui', 'warn'), 3%)
    ),
    info: (
      bg: map-deep-get($brand, 'ui', 'info'),
      fg: $gray-lightest,
      border: darken(map-deep-get($brand, 'ui', 'info'), 5%),
      bghover: lighten(map-deep-get($brand, 'ui', 'info'), 3%)
    ),
    fob: (
      bg: map-deep-get($brand, 'ui', 'fob'),
      fg: darken(map-deep-get($brand, 'ui', 'fob'), 22%),
      border: darken(map-deep-get($brand, 'ui', 'fob'), 5%),
      bghover: lighten(map-deep-get($brand, 'ui', 'fob'), 3%)
    )
  )
);

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;
