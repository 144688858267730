// RACE DETAIL / RESULTS DETAIL
ul.flat-display-list.flat-runners-list {
  li {
    position: relative;
  }

  &.hasInfo {
    background: $gray-lighter;

    > li {
      margin-bottom: 1em;
      background: $gray-lightest;
    }
  }
}

// Knockout Betting-v2
.message-box {
  box-shadow: 0 0 1px 1px #dcdcdc;
  background-color: $gray-lightest;
  padding: 1em;

  .info-tt-icon {
    font-size: 2.5em;
    color: #139edb;
    vertical-align: top;
    width: 1em;
  }

  .warning-tt-icon {
    font-size: 2.5em;
    color: #f9a61d;
    vertical-align: middle;
    width: 1em;
  }

  .info-text {
    display: inline-block;
    font-size: 1.2em;
    line-height: 1.5em;
    text-align: left;
  }

  .warning-text {
    font-size: 1.2em;
    text-align: left;
    padding-top: 0.3em;
    vertical-align: middle;
  }

  a {
    color: #139ebd;
  }
}

@import 'display-list.bpr';
