// Selection Check Boxe Styling with Two Boxes
.selections-checks.duo-select {
  .checked-element-holder {
    display: inline-block;
    height: 3em;
    float: right;
  }

  .checked-element {
    margin-top: 0.5em;
  }

  .selections-prices {
    clear: none;
    width: 6em;
    height: 3em;
  }
}
