.popup {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 202;
  width: 100%;
  display: none;
}

.popup-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 202;
  width: 100%;
  display: none;
}

.popup .overlay {
  position: fixed;
  background: $gray-darkest;
  width: 100%;
  height: 100%;
}

.popup .window h4 {
  font-size: 1.8em;
}

.popup .window.standard-background {
  background: $gray-lightest;
}

.popup .window {
  background-color: $gray-lightest;
  box-shadow: 0 0 10px rgba($gray-darkest, 0.5);
  left: 50%;
  margin: 2em auto 2em -14em;
  padding: 1em;
  position: fixed;
  text-align: center;
  top: 4em;
  width: 26em;
}

.popup .black-x {
  position: absolute;
  top: -15px;
  right: -15px;
  display: block;
  width: 32px;
  height: 32px;
  text-indent: -999px;
  overflow: hidden;
}

.popup .grey-x {
  position: absolute;
  top: -1px;
  right: -1px;
  display: block;
  width: 32px;
  height: 32px;
  text-indent: -999px;
  overflow: hidden;
}

.popup .flat-x {
  position: absolute;
  top: -1px;
  right: -1px;
  display: block;
  width: 1.1em;
  height: 1.1em;
  padding: 0.8em;
  overflow: hidden;
  background-color: $gray-mid;
  color: $gray-lightest;
  font-size: 1.2rem;
}

.popup h3 {
  margin: 0;
}
