// =========================================================
// Deprecated - No new styles to be added to global elements
// =========================================================
h2 {
  color: $gray-lightest;
  font-size: 1.8em;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  line-height: 2.2em;
  text-shadow: 0 0 2px $gray-darkest;
}

h3 {
  background: #2e2c30;
  color: $gray-lightest;
  margin: 1em 0;
  padding: 0 0.8333em;
  line-height: 2.5em;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.2em;

  span.left {
    width: 40%;
    display: block;
  }

  span.middle {
    width: 20%;
    text-align: center;
    display: block;
    float: left;
  }

  span.right {
    float: right;
    width: 40%;
    text-align: right;
    display: block;
  }
}

h4 {
  font-size: 1.6em;
  text-align: center;
  margin: 0.5em 0.625em;
}

h5 {
  font-size: 1.4em;
  text-align: center;
  margin: 0.5em 0.714em;
}

h6 {
  font-size: 1.4em;
  text-align: center;
  font-weight: normal;
  margin: 0.5em 1em;
  text-transform: uppercase;
}

p {
  font-size: 1.4em;
  text-align: center;
}

a {
  color: #49c704;
}

img {
  border-style: none;
}

input,
select,
textarea {
  font-family: map-get($fonts, sec);
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

// ==============================================
// Unset all legacy global styles affecting Kambi
// ==============================================
.mobi__unset-globals {
  h2,
  h3,
  h4,
  h5,
  h6,
  ul li,
  p,
  a,
  img,
  svg,
  input,
  select,
  textarea,
  fieldset {
    all: revert;
    -webkit-text-fill-color: initial;
  }
}
