.dividend .flat-favorite {
  position: absolute;
  overflow: hidden;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.2rem;
  color: #f7a800;
}

.dividend.fixed .flat-favorite {
  left: -0.5rem;
  top: -0.4rem;
}

.dividend.tote .flat-favorite {
  right: -0.5rem;
  top: -0.4rem;
}
