// Selection Check Boxe Styling with One Box
.selections-checks.single-select {
  min-width: 11em;
  margin-top: 1em;

  .checked-element-holder {
    width: 3em;
    display: inline-block;
    height: 3em;
    float: right;
  }

  .selections-prices {
    width: 6em;
    height: 3em;
    margin: 0;
    text-align: right;

    .selections-prices-fixed span:last-of-type,
    .selections-prices-tote span:last-of-type {
      display: none;
    }

    .selections-prices-fixed {
      display: block;
    }

    .selections-prices-tote {
      display: block;
      float: right;
    }
  }
}
