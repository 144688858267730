.flat-runners-list .dividends .dividend.fixed,
.flat-runners-list .dividends .dividend.tote {
  box-sizing: border-box;
  float: left;
  width: 5rem;
  height: 5rem;
  margin-left: 0.3rem;
  padding: 0.6em;

  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.4em;
}

.flat-runners-list .dividends .dividend {
  background: #ddd;
  background: linear-gradient(to bottom, $gray-lightest, $gray-nearwhite);
  display: block;
  text-align: center;
  box-shadow: inset 0 1px 0 0 $gray-lightest, 0 0 1px rgba($gray-darkest, 0.1);
  border: 1px solid $gray-light;
  height: 2.5em;
  font-size: 1.1em;
}

.flat-runners-list .dividends .dividend {
  float: left;
  width: 4em;
}

.flat-runners-list .dividends .dividend.tote {
  border-radius: 0.3em;
  color: #222;
  border-left: 1px solid $gray-light;
}

.flat-runners-list .dividends .dividend.fixed {
  border-radius: 0.3em;
  color: $gray-darker;
  margin-right: 4px;
}
