/// CORE STYLES
.flat-display-list-header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #eee;
  padding-right: 0.75em;

  .number-col {
    width: auto;
    margin-left: 1rem;
    margin-right: auto;
    text-align: center;
  }

  .fixed-col,
  .tote-col {
    width: 5rem;
  }

  .tote-col {
    margin-left: 0.3rem;
  }

  .toggle-col {
    width: 4.4rem;
  }
}

ul.flat-display-list {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  background-color: #fff;
}

ul.flat-display-list li {
  border-bottom: 1px solid #eee;
  clear: both;
  font-size: 1.2em;
  list-style: outside none none;
  margin: 0;
  position: relative;
}

#multi-leg ul.flat-display-list,
#allup ul.flat-display-list {
  padding: 0 0.5em 0.5em;
  background-color: #eee;
}

#multi-leg ul.flat-display-list li,
#allup ul.flat-display-list li {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#multi-leg ul.flat-display-list li:last-child,
#allup ul.flat-display-list li:last-child {
  border-top: 1px solid #eee;
  box-shadow: 0 9px 0 0 #fff, 0 -9px 0 0 #fff, 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

ul.flat-display-list li .race-starter-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1em 0.75em;
  position: relative;
  overflow: hidden;

  > div:first-of-type {
    flex: 1;
  }

  &.stacked_selection {
    flex-direction: column;
  }
}

ul.flat-display-list.resulted-runners-list li .race-starter-inner {
  padding: 0.75em;
}

ul.flat-display-list li .race-starter-inner .starter-info {
  cursor: pointer;
}

ul.flat-display-list li.alt {
  background-color: #f4f4f4;
  position: relative;
}

ul.flat-display-list li a {
  padding: 0 1em;
  display: block;
  color: #000;
  text-decoration: none;
  margin: 0 auto;
  position: relative;
  line-height: 2.5em;
}

ul.flat-display-list span.title {
  display: inline-block;
  width: 30%;
  vertical-align: top;
  line-height: 2em;
  padding-left: 1em;
}

ul.flat-display-list span.data {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  line-height: 2em;
}

ul.flat-display-list li > strong {
  margin-left: 1em;
}

ul.flat-display-list li span.cancelled {
  color: #f00;
  background-position: 0 center;
  padding-left: 25px;
}

ul.flat-display-list li.disabled {
  color: #999 !important;
}

ul.flat-display-list ul {
  margin: 0;
  padding: 0;
}

ul.flat-display-list ul li {
  font-size: 1em;
  padding: 0 1em;
}

ul.flat-display-list ul li span.title,
ul.flat-display-list ul li span.data {
  line-height: 1em;
}

ul.flat-display-list span.price {
  background-position: right 7px;
  background-repeat: no-repeat;
  width: 25%;
  margin-right: 10px;
  padding-right: 15px;
}

ul.flat-display-list span.return {
  background-repeat: no-repeat;
  text-align: right;
  float: right;
  padding-right: 15px;
  background-position: right 6px;
}

ul.flat-display-list span.down {
  background-image: url('../images/content/loss-arrow.png');
  color: #f30;
}

ul.flat-display-list span.up {
  background-image: url('../images/content/gain-arrow.png');
  color: #84c447;
}

// =============================
// Scratched and Late-Scratching
// =============================

ul.flat-display-list li div.scr span {
  text-transform: uppercase;
  color: #f00;
}

ul.flat-display-list li div.scr span {
  width: 58px;
  text-align: center !important;
  font-size: 0.8em;
}

ul.flat-display-list li div.scr span.scratching {
  margin-top: 1.5em;
}

ul.flat-display-list.flat-runners-list li.scratched .runner-number,
ul.flat-display-list.flat-runners-list li.scratched span.selection-name {
  text-decoration: line-through;
}

ul.flat-display-list li.scratched .runner__scratched-deductions,
ul.flat-display-list.flat-runners-list li.scratched span.selection-name,
ul.flat-display-list.flat-runners-list li.scratched .selection-info,
ul.flat-display-list.flat-runners-list li.scratched .runner-number {
  color: #a8a8a8;
  height: auto;
  min-height: auto;
}

ul.flat-display-list.flat-runners-list li.scratched .runner-number,
ul.flat-display-list.flat-runners-list li.scratched span.selection-name {
  color: #818181;
}

ul.flat-display-list li.scratched .runner__scratched-label {
  clear: both;
  text-transform: uppercase;
}

display-scratched-deductions { display: flex; }

ul.flat-display-list li.scratched .runner__scratched-deductions {
  align-self: center;
  font-size: 1.1rem;
  text-align: center;

  strong {
    color: #818181;
    display: block;
    font-size: 1.2rem;

    > span:nth-of-type(2) {
      padding-left: 0.5rem;
    }
  }
}

// ============================
// RACE DETAIL / RESULTS DETAIL
// ============================

ul.flat-display-list.flat-runners-list li {
  position: relative;
}

ul.flat-display-list.flat-runners-list li .resultedList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

ul.flat-display-list.flat-runners-list li .resultedList .selection-info {
  flex: 1;

  .rider,
  .trainer {
    color: #666;
    margin-left: 2.5rem;
  }
}

ul.flat-display-list.flat-runners-list li .resultedList .race-starter-inner {
  padding: 0.75em;
}

ul.flat-display-list.flat-runners-list li .resultedList .resultedSilkPlace {
  align-items: center;
  display: flex;
  flex-direction: column;

  finisher-place {
    width: 3rem;
  }
}

ul.flat-display-list li .resultedList span.starter-runner-number span.silk {
  margin-top: 0;
}

ul.flat-display-list li .resultedList span.starter-runner-number.form-button {
  height: 2.8rem;
}

ul.flat-display-list li .resultedList span.starter-runner-number.form-button.single-silk-matched-resulted {
  height: 4.2rem;
}

ul.flat-display-list .dogs-list .resultedList span.runner-number .overlay {
  width: 1.65rem;
  height: 1.65rem;
  margin-top: 0.5em;
  margin-left: -0.1rem;
  border: 1px solid #000;
}

ul.flat-display-list.flat-runners-list li .selection-info {
  line-height: 1.2em;
  min-height: 48px;
  overflow: hidden;
  padding: 0 0 0 0.9em;
  color: #666;
}

ul.flat-display-list.flat-runners-list.resulted li .selection-info {
  float: left;
  padding: 0.5em 0.8em 0.2em 0.4em;
  overflow: hidden;
}

ul.flat-display-list.flat-runners-list.has-win li .selection-info {
  margin-left: 2.857142857142857em;
}

ul.flat-display-list.flat-runners-list li span.selection-name {
  color: rgb(0, 0, 0);
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  margin: 0 0 0.3rem;
  text-transform: unset;
  word-wrap: break-word;
}

ul.flat-display-list.flat-runners-list li div.selection-form {
  display: flex;

  .selection-form-table {
    flex-grow: 1;
  }

  .selection-form-weight-barrier {
    display: flex;
    flex: 1;
    justify-content: space-between;
    text-align: left;
    max-width: 15rem;
  }

  .mbquarterem {
    margin-bottom: 0.25rem;
  }

  .horizontalStacking {
    float: left;
    margin-right: 1rem;
  }

  .starter-trainer-name {
    // truncate the trainer name
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-device-width: 320px) {
  .hidden-ip5 {
    display: none;
  }
}


@media only screen and (max-device-width: 768px) {
  .resultedSilkPlace > finisher-place {
    width: unset !important;
  }
}

ul.flat-display-list.flat-runners-list li .flat-starter-data {
  color: #222;
  font-size: 0.9166666666666667em;
  padding: 0.12em 0;
  font-weight: bold;
  display: block;
}

ul.flat-display-list.flat-runners-list li .flat-starter-data-item {
  display: inline-block;
  min-width: 3.5em;
  margin-right: 0.2em;
}

ul.flat-display-list.flat-runners-list li .flat-starter-data span {
  font-weight: 400;
}

ul.flat-display-list.flat-runners-list li input {
  float: right;
  margin: 0.8em 0.8em 1.2em;
}

ul.flat-display-list li span.handicap {
  color: #666;
}

ul.flat-display-list.flat-runners-list li span.win {
  padding: 0 0.333333333333em;
  border-radius: 0.3333333333em;
  margin-top: 1em;
  display: inline-block;
  vertical-align: top;
  text-shadow: none;
  font-size: 0.8333em;
  font-weight: bold;
  border: 0.1em solid #000;
  line-height: 1.6em;
}

ul.flat-display-list.flat-runners-list.has-win li span.win {
  position: absolute;
  top: -1px;
  left: 10px;
}

ul.flat-display-list.field .dividends {
  width: 9em;
  float: right;
}

ul.flat-display-list.resulted .dividends {
  width: 9.2em;
  float: right;
}

ul.flat-display-list.resulted.has-win .dividends {
  width: 8.2em;
}

ul.flat-display-list.field.flat-runners-list li .selection-info,
ul.flat-display-list.resulted.flat-runners-list li .selection-info {
  padding-right: 0;
  padding-left: 0;
  width: 39%;
}

ul.flat-display-list.resulted.has-win li .selection-info {
  padding-left: 1em;
}

.flat-runners-list .dividends .dividend.scratched,
.flat-runners-list .dividends .dividend.disabled,
.flat-runners-list .dividends .dividend.suspended {
  background: #eee;
  box-shadow: none;
  box-shadow: none;
  color: #999;
  cursor: auto;
}

.flat-runners-list.field .dividends .scratched,
.flat-runners-list .dividends .dividend.scratched {
  background: rgb(255, 255, 255) none repeat scroll 0 0;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(254, 0, 0);
  line-height: 2.8em;
  text-align: center;
}

.flat-runners-list .dividends .dividend.suspended {
  color: #222;
  background: #fff;
  font-weight: bold;
  border: 1px solid #fff !important;
  text-align: center;
}

.flat-runners-list .dividends .dividend.scratched span {
  text-align: center;
}

.flat-runners-list.field .dividends .scratched {
  width: 5.5em;
  font-size: 0.8em;
  float: right;
  text-align: right;
  margin-top: 0.5em;
  line-height: 1.5em;
  text-transform: uppercase;
}

.flat-runners-list .dividends {
  font-size: 0.715em;
  float: right;
}

ul.flat-display-list .selection-checks {
  width: 9em;
  float: right;
}

.flat-runners-list.field .dividends,
.flat-runners-list.resulted .dividends {
  margin-top: 0.2em;
}

.flat-runners-list .dividends .dividend {
  padding: 8px 6px 6px 9px;
  line-height: 1em;
  height: 2em;
  text-align: right;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

.flat-runners-list .dividends .dividend.selected {
  color: #fff;
  text-shadow: none;
}

.flat-runners-list .dividends .dividend.tote.selected {
  background: #222;
  background: -webkit-gradient(linear,
  left top,
  left bottom,
  color-stop(1%, #222),
  color-stop(100%, #222));
  background: linear-gradient(to bottom, #222 1%, #222 100%);
  border-color: #222;
}

.flat-runners-list .dividends .dividend.fixed.selected {
  background: #222;
  background: -webkit-gradient(linear,
  left top,
  left bottom,
  color-stop(1%, #222),
  color-stop(100%, #222));
  background: linear-gradient(to bottom, #222 1%, #222 100%);
  border-color: #222;
}

.flat-runners-list.field .dividends,
.flat-runners-list.resulted .dividends {
  font-size: 1em;
}

.flat-runners-list.resulted .dividends {
  font-size: 1em;
}

.flat-display-list.dividends span.dividend {
  text-align: right;
}

ul.flat-display-list li span.runner-number {
  border: 0;
  color: #333;
  font-size: 1.3em;
  line-height: 1.7em;
  text-align: center;
  font-weight: bold;
  float: left;
  position: relative;
}

ul.flat-display-list li span.runner-number.resulted {
  background: #ddd;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#e9e9e9), to(#d9d9d9));
  border-radius: 0.3em;
  border-radius: 0.3em;
  border-bottom: 1px solid #bbb;
  border-top: 1px solid #eee;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #333;
  font-size: 1.3em;
  line-height: 1.7em;
  width: 1.8em;
  text-align: center;
  font-weight: bold;
  margin: 0.5em;
  float: left;
  position: relative;
}

.resulted-runners-list .finisher-description .finisher-number {
  width: 1.5em;
  display: inline-block;
  text-align: center;
  font-size: 1em;
  height: 1.5em;
  line-height: 1.5em;
  margin: 0;
}

ul.flat-display-list li span.starter-runner-number .number {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  height: 1.6rem;
  line-height: 1.6rem;
  margin: 0 auto;
  width: 20px;
}

.resulted-runners-list .finisher-description .finisher-name {
  padding-left: 0.5em;
}

// no form
ul.flat-display-list li span.starter-runner-number {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3em;
  height: 3.4em;
  text-align: right;
  float: left;
  padding: 0 0 0 1px;
}

// with form
ul.flat-display-list li span.starter-runner-number.form-button {
  color: rgb(34, 34, 34);
  font-size: 1em;
  font-weight: bold;
  height: 4.2em;
  padding: 0;
  position: relative;
  text-align: center;
}

// basic Silk Style

ul.flat-display-list li span.starter-runner-number span.silk {
  display: block;
  height: 2.5em;
  margin-top: 0.5em;
  width: 2.083333333333333em;
  margin-left: -0.3em;
  font-size: 1em;
}

// Resulted
ul.flat-display-list li span.starter-runner-number.finisher .number {
  height: auto;
  min-width: 3rem;
  padding: 0.2rem 0;
  border-radius: 3px;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  text-align: center;
  color: #fff;
  background-color: #8246af;
}

ul.flat-display-list li span.starter-runner-number.finisher .number .ordinal {
  font-size: 0.8em;
  position: relative;
}

@include media('screen', 'width>=small') {
  ul.flat-display-list li span.starter-runner-number span.silk {
    display: block;
    width: 2.916666666666667em;
    height: 3.5em;
    font-size: 0.625em;
  }
}

ul.flat-display-list li span.starter-runner-number span.silk.trot {
  width: 1.5em;
  margin: 0.1rem auto 0 0;
  height: 2.166666666666667em;
}

@include media('screen', 'width>=small') {
  ul.flat-display-list li span.starter-runner-number span.silk.trot {
    width: 2.33333em;
    margin: 0.9em auto 0 -0.2rem;
    height: 3em;
    font-size: 0.625em;
  }
}

ul.flat-display-list.resulted li span.runner-number {
  background: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  z-index: 999;
  color: #333;
  font-size: 1.3em;
  line-height: 1.7em;
  font-weight: bold;
  position: relative;
  top: -1.4em;
  right: -0.5em;
}

// dogs list

ul.flat-display-list .dogs-list span.runner-number .overlay {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: 0.2em;
  margin-left: 0;
  font-size: 1em;
  border: 1px solid #000;
}

ul.flat-display-list .dogs-list .rider {
  margin-top: 0.5em;
}

ul.flat-display-list .dogs-list .dog-1 {
  background: #f00 !important;
  border: 1px solid #7b0100;
}

ul.flat-display-list .dogs-list .dog-2 {
  background: #fff !important;
  border: 1px solid #000;
  position: relative;
}

ul.flat-display-list .dogs-list .dog-2 div .stripe-1,
ul.flat-display-list .dogs-list .dog-2 div .stripe-2,
ul.flat-display-list .dogs-list .dog-2 div .stripe-3 {
  height: 0.3em;
  width: 100%;
  position: absolute;
  background: #000;
  border: 0;
  left: 0;
}

ul.flat-display-list .dogs-list .dog-2 div .stripe-1 {
  top: -1%;
}

ul.flat-display-list .dogs-list .dog-2 div .stripe-2 {
  top: 40%;
}

ul.flat-display-list .dogs-list .dog-2 div .stripe-3 {
  top: 80%;
}

ul.flat-display-list .dogs-list .dog-3 {
  background: #fff !important;
  border: 1px solid #999;
  color: #000;
}

ul.flat-display-list .dogs-list .dog-4 {
  background: #1c96f4 !important;
  border: 1px solid #0f4875;
}

ul.flat-display-list .dogs-list .dog-5 {
  background: #f4f11c !important;
  border: 1px solid #74720e;
}

ul.flat-display-list .dogs-list .dog-6 {
  background: #2d7e33 !important;
  border: 1px solid #173c1a;
}

ul.flat-display-list .dogs-list .dog-7 {
  background: #222 !important;
  border: 1px solid #000;
}

ul.flat-display-list .dogs-list .dog-8 {
  background: #d738d1 !important;
  border: 1px solid #661b63;
}

ul.flat-display-list .dogs-list .dog-9 {
  background: #fff !important;
  border: 1px solid #aaa;
  position: relative;
}

ul.flat-display-list .dogs-list .dog-9 div .stripe-1,
ul.flat-display-list .dogs-list .dog-9 div .stripe-2 {
  background: #35833b;
  height: 0.4em;
  width: 100%;
  position: absolute;
  left: 0;
}

ul.flat-display-list .dogs-list .dog-9 div .stripe-1 {
  top: 0;
}

ul.flat-display-list .dogs-list .dog-9 div .stripe-2 {
  background: #35833b;
  top: 52%;
}

ul.flat-display-list .dogs-list .dog-10 {
  background: #fff !important;
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-top: 1px solid #8c74d9;
  position: relative;
}

ul.flat-display-list .dogs-list .dog-10 div .stripe-1,
ul.flat-display-list .dogs-list .dog-10 div .stripe-2 {
  height: 33.333%;
  width: 100%;
  position: absolute;
  left: 0;
}

ul.flat-display-list .dogs-list .dog-10 div .stripe-1 {
  top: 0;
  background: #280eae;
}

ul.flat-display-list .dogs-list .dog-10 div .stripe-2 {
  background: #cb0a0a;
  top: 33.3%;
}

ul.flat-display-list .dogs-list li span.runner-number.empty {
  border: 0;
}

ul.flat-display-list.resulted li span.runner-place {
  color: #fff;
  font-size: 1.3em;
  float: left;
  margin: 0.1em 0 0.1em 0.1em;
  text-shadow: 0 -1px 0 #dbaf02;
}

ul.flat-display-list.resulted li span.runner-place sup {
  margin: 0;
  padding: 0;
  line-height: 5px;
  font-size: 0.5em;
}

ul.flat-display-list.resulted li span.runner-number {
  background: none;
  border: 0;
  box-shadow: none;
  z-index: 999;
  color: #333;
  font-size: 1.3em;
  line-height: 1.7em;
  width: auto;
  font-weight: bold;
  position: absolute;
  top: 0.4em;
  right: 0.2em;
  margin: 0;
  padding: 0;
}

ul.flat-display-list.flat-runners-list.resulted li.rider-change span.rider {
  left: 100px;
}

ul.flat-display-list li.rider-change span.rider span {
  color: #fff;
  padding: 0 0.5em;
  background: #cd92c8;
  text-shadow: none;
  border-radius: 0.3em;
}

// scratched

ul.flat-display-list.flat-runners-list li.scratched input {
  display: none !important;
}

// provisional dividends
ul.flat-display-list.bold {
  font-weight: bold;
}

ul.flat-display-list.with-icon li a {
  padding-left: 3.5em;
}

ul.flat-display-list.with-icon li a {
  padding-left: 3.5em;
}

ul.flat-display-list .prop-name {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
}

ul.flat-display-list .prop-number {
  font-weight: normal;
  font-size: 0.85em;
}

ul.flat-display-list .leg-type {
  font-weight: normal;
  font-size: 0.85em;
}

ul.flat-display-list.proposition-list li input {
  float: right;
  margin: 0.5em 0 0.3em;
}

ul.flat-display-list.proposition-list li.suspended span,
ul.flat-display-list.proposition-list li.scratched span {
  color: #aaa;
}

ul.flat-display-list.proposition-list li span.scratched,
ul.flat-display-list.proposition-list li span.suspended {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.85em;
  color: #f00;
  float: right;
  display: block;
}

ul.flat-display-list.proposition-list.racing li span.scratched,
ul.flat-display-list.proposition-list.racing li span.suspended {
  margin-left: 2.3em;
}

ul.flat-display-list.proposition-list li span.scratched {
  color: #f00;
  margin: 0;
  text-align: left;
}

/* keep Giddy Up vertically aligned for scratched starters */
win-place-bettype span.scratched {
  width: 8.5em;
  position: relative;
  left: 0.7rem;
}

first-four-bettype span.scratched {
  width: 11.65em;
  position: relative;
  left: 0.7rem;
}

exacta-bettype span.scratched {
  width: 11.2em;
  position: relative;
  left: 0.7rem;
}

trifecta-bettype span.scratched {
  width: 11.7em;
  position: relative;
  left: 0.7rem;
}

quinella-bettype span.scratched {
  width: 8.3em;
  position: relative;
  left: 0.7rem;
}

allup-bettype span.scratched {
  width: 8.3em;
  position: relative;
  left: 0.7rem;
}

multi-leg-bettype span.scratched {
  width: 8.3em;
  position: relative;
  left: 0.7rem;
}
/* /end fix width for starter panels */

ul.flat-display-list.proposition-list li {
  padding: 0.5em;
  vertical-align: top;
}

ul.flat-display-list.proposition-list li .selection-info {
  float: left;
  width: 60%;
}

ul.flat-display-list.proposition-list li span {
  vertical-align: top;
}

ul.flat-display-list.proposition-list li span.number {
  height: 1.5em;
  width: 1.5em;
  background: #ddd;
  text-align: center;
  border-radius: 0.3em;
  font-weight: bold;
  margin-right: 0.5em;
  display: inline-block;
  float: left;
}

ul.flat-display-list.proposition-list li span.event {
  font-weight: bold;
}

ul.flat-display-list li a.remove {
  background: #c90e0e url('../images/buttons/remove.png') no-repeat center center;
  background: url('../images/buttons/remove.png') no-repeat center center,
    -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(0, #de6b6b), color-stop(1, #c90e0e));
  background: url('../images/buttons/remove.png') no-repeat center center,
    -moz-linear-gradient(to bottom, #de6b6b, #c90e0e);
  border-top: 1px solid #eea3a3;
  border-bottom: 1px solid #760505;
  border-left: 1px solid #d33a3a;
  border-right: 1px solid #d33a3a;
  border-radius: 0.3em;
  box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.3);
  float: left;
  width: 1.7em;
  height: 1.7em;
  margin: 0.3em 0.5em 0;
  text-indent: -999px;
  overflow: hidden;
  padding: 0;
  z-index: 2;
}

ul.flat-display-list li a.info {
  background: #369cc7 url('../images/buttons/information.png') no-repeat center center;
  background: url('../images/buttons/information.png') no-repeat center center,
    -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(0, #68b5d5), color-stop(1, #0886ba));
  background: url('../images/buttons/information.png') no-repeat center center,
    -moz-linear-gradient(to bottom, #68b5d5, #0886ba);
  border-top: 1px solid #9fd9ea;
  border-bottom: 1px solid #025795;
  border-left: 1px solid #369cc7;
  border-right: 1px solid #369cc7;
  border-radius: 0.3em;
  border-radius: 0.3em;
  box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.3);
  float: right;
  width: 1.7em;
  height: 1.7em;
  margin: 0.3em 0.5em 0;
  text-indent: -999px;
  overflow: hidden;
  padding: 0;
  z-index: 2;
}

// RESULTS LISTS

ul.flat-display-list.dividends li,
ul.flat-display-list.scr li {
  padding: 0.5em 1em;
}

ul.flat-display-list.dividends span,
ul.flat-display-list.src span {
  display: inline-block;
}

ul.flat-display-list.dividends span.dividend {
  float: right;
}

ul.flat-display-list.dividends span.bet-type {
  width: 8em;
}

ul.flat-display-list.scr span.sub-number {
  width: 2em;
  line-height: 2em;
  background: #ddd;
  border-radius: 0.3em;
  text-align: center;
  margin: 0 0.5em 0 0;
  float: left;
}

ul.flat-display-list.scr span.dividend-value {
  width: 3em;
  text-align: right;
  float: right;
  line-height: 2em;
}

ul.flat-display-list.scr span.dividend-value {
  font-size: 0.8em;
  line-height: 1.2em;
}

ul.flat-display-list.scr span.dividend-name {
  float: right;
  line-height: 2em;
  margin-left: 5px;
  font-size: 0.8em;
  line-height: 1.2em;
  color: #666;
}

.flat-display-list.flat-display-list-dl li span {
  display: inline-block;
  line-height: 1em;
  padding: 0.5em 0;
}

.flat-display-list.flat-display-list-dl li span.wide {
  width: 50%;
}

ul.flat-display-list.flat-runners-list.no-number .selection-info {
  padding: 0.9em 0 0.8em 1em;
  min-height: 33px;
}

ul.flat-display-list.flat-runners-list.has-win .selection-info {
  min-height: 0;
}

ul.flat-display-list.flat-runners-list.no-number .dividends {
  margin: 0.5em 1em 0.5em 0;
}

// Default a higher line-height when using BS columns
ul.flat-display-list li.row {
  line-height: 2;
}

// Specials in display lists
ul.flat-display-list li .tticon.tticon-special {
  display: inline-block;
  margin-top: 0.3333333333em;
  font-size: 1.2857142857em;
  vertical-align: top;
  text-shadow: none;
}

ul.flat-display-list li .tticon.tticon-jackpot {
  position: relative;
  display: inline-block;
  margin-top: -0.0666666666em;
  font-size: 2.1428571428em;
  vertical-align: top;
  text-shadow: none;
}

ul.flat-display-list li .tticon.tticon-jackpot::before {
  z-index: 2;
  color: #f2a900;
}

ul.flat-display-list li .tticon.tticon-jackpot.tticon-jackpot-blackbg {
  &::after {
    position: absolute;
    top: 0.2333333333em;
    left: 0.1333333333em;
    z-index: 1;
    width: 0.7em;
    height: 0.7em;
    background-color: #000;
    border-radius: 0.3666666666em;
    content: '';
  }
}

ul.flat-display-list.simple {
  margin-top: 0;
  margin-bottom: 2em;
  background-color: transparent;
}

ul.flat-display-list.simple .alt {
  background-color: transparent;
}

.roving-banker {
  margin-right: 0.5em;
}

.single-silk-matched {
  margin-bottom: 0.6rem;
}

.single-silk-matched-resulted {
  margin-left: 0.5rem;
}
