// Selection Check Box Styling - First Four
.selections-checks.quad-select {
  width: 12em;

  &.allways {
    width: 8.5em;
  }

  .selections-prices {
    clear: none;
  }

  .selections-prices.allways {
    float: right;
    width: 4.5em;
    height: 3em;

    .selections-prices-tote:last-child {
      margin-right: 0.5em;
    }

    .selections-prices-tote:last-child span:last-child {
      margin-right: 0.45em;
    }
  }

  .selections-prices.roving {
    .selections-prices-tote:last-child {
      margin-right: 0.5em;
    }

    .selections-prices-tote:last-child span:last-child {
      margin-right: 0.45em;
    }
  }

  .checked-element.roving,
  .checked-element.boxed {
    margin-top: 0.5em;
  }
}
