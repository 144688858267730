.flat-column-header {
  padding: 0.7rem 1rem;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: $gray-lightest;

  .flat-column-header-item {
    float: right;
    font-size: 1.2em;
    font-weight: bold;
    min-width: 4.2em;
    text-align: center;
    position: relative;
    color: #552d6b; // Standard

    .special-bright {
      font-size: 1.6em;
      position: absolute;
      top: -0.1em;
      left: -0.7em;
    }
  }

  .flat-column-header-item.tote {
    color: #512d6d !important;
  }

  .flat-column-header-item.fixed {
    color: #2f8890 !important;
  }
}

.results {
  .flat-column-header {
    .flat-column-header-item {
      text-align: right;
      padding-right: 0.3em;
    }
  }
}
