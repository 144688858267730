@font-face {
  font-family: 'PFSquare';
  src: url('../fonts/pfsquaresanspro_regular-webfont.woff') format('woff'),
    url('../fonts/pfsquaresanspro_regular-webfont.ttf') format('truetype'),
    url('../fonts/pfsquaresanspro_regular-webfont.svg#PFSquare') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PFSquareBold';
  src: url('../fonts/pfsquaresanspro_bold-webfont.woff') format('woff'),
    url('../fonts/pfsquaresanspro_bold-webfont.ttf') format('truetype'),
    url('../fonts/pfsquaresanspro_bold-webfont.svg#PFSquareBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'tticons';
  src: url('../fonts/tticons.woff2') format('woff2'),
    url('../fonts/tticons.woff') format('woff'),
    url('../fonts/tticons.ttf') format('truetype'),
    url('../fonts/tticons.svg#tticons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 700;
  src: url('../fonts/InterVariable.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400 700;
  src: url('../fonts/InterVariable-Italic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
