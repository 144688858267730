// TEMP RESET STYLED-COMPONENTS
// this is needed to temporarily overwrite some global
// scss styling that will eventually be removed (such as the global `p { text-align:center }` ).
// Rather than write all the overwrites into the styled components
// (which will become redundant once the scss is removed)
// we can add the overwrites here and then delete
// these along with the old scss when everything is rewritten with CSS-in-JS.
// Leaving clean, lean styled components.
// This works as all styled-components compile with a class
// that begins with 'css-' ie. css-hfdyer643jen.

p[class^='css-'] {
  text-align: left;
}

h2[class^='css-'] {
  text-shadow: none;
}
