@import 'bootstrap/variables';
@import 'bootstrap/mixins';

@import 'base/defaults'; // defaults always on top!
@import 'base/align-box-text';
@import 'base/align';
@import 'base/colors';
@import 'base/display';
@import 'base/font-size';
@import 'base/line-height';
@import 'base/margin-padding';
@import 'base/position';
@import 'base/text';
@import 'base/width';

@import 'layout/l-faux-table';

@import 'modules/card';
@import 'modules/spinner';

// Fade In Effect
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
