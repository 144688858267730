// `.investment-input` style is currently (only) used on the account/deposit view.
// The styles are currently duplicated as the input on the deposit page (coming from rwwa-knockout)
// were broken due to changes intended to target the inputs in the betting investment component (mobi).
.investment-input {
  .title {
    background-color: #794497;
    color: $gray-lightest;
    height: 2.5em;
    line-height: 2.5em;
    padding-left: 1em;
  }

  .input-raw {
    border-bottom: 1px solid $gray-light;
    padding-bottom: 5px;
    padding-left: 1.2em;
    padding-top: 5px;

    .input-with-symbol {
      display: table-cell;
      position: relative;
      width: 70%;

      .input-symbol {
        border: 0;
        font-size: 1.7em;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      .input-amount {
        border: 0;
        font-size: 1.7em;
        height: 2.5em;
        text-indent: 0.55em;
        width: 99%;
        appearance: textfield;

        &::-ms-clear {
          display: none;
        }

        &:focus {
          outline: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          // display: none; <- Crashes Chrome on hover
          appearance: none;
          margin: 0; // <-- Apparently some margin are still there even though it's hidden
        }
      }
    }

    .clear {
      color: $gray-light;
      display: table-cell;
      font-size: 2em;
      padding-right: 0.5em;
      text-align: right;
      vertical-align: middle;
      width: 30%;
    }

    .clear.red {
      color: #cd0000;
    }
  }

  .input-raw.purple-top-border {
    border-bottom-color: #794497;
  }

  .quick-button {
    border-right: 1px solid #d3d3d3;
    box-sizing: border-box;
    color: #794497;
    font-weight: bold;
    height: 4em;
    line-height: 4em;
    text-align: center;
  }

  .quick-button:active {
    background-color: $gray-lighter;
  }

  .quick-button.last {
    border-right-width: 0;
  }
}
