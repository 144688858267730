/* _spinner.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

// Examples
// Inline: <span class="spinner spinner-purple alignmid"></span>
// Section with overlay: <span class="spinner posa overlay center fz-24"></span>
// Full page with overlay & fixed: <span class="spinner posf overlay center fz-63"></span>

.spinner {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  float: none; // try to remove some generic effects, not that it'll help (specificity!)
  width: auto; // try to remove some generic effects, not that it'll help (specificity!)
  height: auto; // try to remove some generic effects, not that it'll help (specificity!)
  z-index: 9999999; // same as existing .ui-loader *ugh*

  &.overlay {
    background-color: rgba($gray-darkest, 0.4);
  }

  &::before {
    display: inline-block;
    width: 1em; /* 12/12 */
    height: 1em; /* 12/12 */
    border: 0.25em solid rgba($gray-lightest, 0.4); /* 3/12 */
    border-left-color: $gray-lightest;
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    animation: rotate360 1.1s infinite linear;
  }

  &.center::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.5em; /* 6/12 */
  }

  &.spinner-purple {
    &::before {
      border-color: rgba(map-deep-get($brandacc, 'pri', 'base', 'bg'), 0.4);
      border-left-color: map-deep-get($brandacc, 'pri', 'base', 'bg');
    }
  }

  &.fz-42,
  &.fz-48,
  &.fz-55,
  &.fz-63,
  &.fz-73,
  &.fz-84,
  &.fz-96 {
    &::before {
      border-width: 0.125em; /* 5.25/42 - 12/96 */
    }
  }
}
