// Base Styling for Selection Check Boxes (Ticks)
.selections-checks {
  min-width: 8em;
  position: relative;
  float: right;
  min-height: 4em;

  .checked-element-holder {
    height: 3em;
    text-align: right;
    float: right;
  }

  .fixedOn {
    .selections-price-fixed,
    .selections-price-tote {
      transform: translateY(0%) !important;
    }
  }

  .selections-prices {
    text-align: right;

    &.stacked {
      .selections-prices-outer {
        height: 4em;
      }

      .selections-price-fixed,
      .selections-price-tote {
        height: 4em;
      }
    }

    .selections-prices-outer {
      height: 3em;
      overflow: hidden;
    }

    .selections-price-fixed,
    .selections-price-tote {
      transform: translateY(-100%);
      transition-property: transform;
      transition-duration: 0.25s;
      transition-timing-function: ease-in-out;
      height: 3em;
    }

    .scratched {
      color: #fe0000;
      font-weight: bold;
    }

    span {
      color: #707070;
    }
  }

  .selections-prices.stacked {
    width: 5.5em;
  }

  .wp-des {
    min-width: 1em;
    display: inline-block;
  }

  .selection-price-amount {
    font-size: 1em;
    color: $gray-dark !important;
  }

  .maxAtIndexZero .index0 :not(.active) {
    color: $gray-nearwhite;
  }
}
