.important {
  color: #e48b0d !important;
}

.left-5 {
  margin-left: 0.5em !important;
}

.right-5 {
  margin-right: 0.5em !important;
}
// end no use

.alert {
  background: url('../images/icons/23x23-important.png') no-repeat 0 0.3em;
  padding: 0.5em 0 0.5em 2.5em;
  text-align: left;
}

.text-ellipsis {
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
