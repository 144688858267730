// wraps multi-type-selection
.race-sub-holder {
  background-color: $gray-lighter;
}

.multi-type-selection {
  border-bottom: 1px solid #e5e5e5;
  background-color: $gray-lightest;
  font-size: 1.2em;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);

  &.dummy {
    position: relative;
    visibility: hidden;
  }
}

.race-sub-holder .posr:first-child .race-sub-header .multi-type-selection {
  border-top: 1px solid #e5e5e5;
}

.multi-type-selection.open {
  background-color: #713d90;
  color: $gray-lightest;
}

.multi-type-selection .arrow {
  font-size: 1.6em;
}

.fixed {
  .multi-type-selection.open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5; // Below AppHeader
  }
}
