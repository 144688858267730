.resulted-runners-list .dividends .dividend {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  width: 3.6em !important;
  padding-left: 0 !important;
}

.resulted-runners-list .dividends .dividend.tote span,
.resulted-runners-list .dividends .dividend.fixed span {
  display: block;
  text-align: right;
}

.disabled-dividend {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  width: 3.6em !important;
  padding-left: 0 !important;
}

// Race Closed Event
#closed-starters {
  .dividends .dividend {
    background: none;
    border: 0;
    box-shadow: none;
    width: 3.6em;
    padding-left: 0;
    pointer-events: none;
  }

  .dividends .dividend.tote span,
  .dividends .dividend.fixed span {
    display: block;
    text-align: right;

    &.tticon-star {
      display: none;
    }
  }
}
