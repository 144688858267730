/* _margin-padding.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

// declare vars for writing generic margin and padding classes
$mpEmSize: 10;
$mpEmMinMargin: -10;
$mpEmMinPadding: 0.5;
$mpEmSizeWhile: $mpEmSize;

$mpSize: 70;
$mpSizeWhile: $mpSize;
$mpMinMargin: -15;
$mpMinPadding: 0;
$mpDirections: top, right, bottom, left;

// all sides margin and padding in ems
@while $mpEmSizeWhile >= $mpEmMinMargin {
  $mpEmName: str-replace(str-replace(to-string($mpEmSizeWhile), '0.', '.'), '.5', 'half');
  .m#{$mpEmName}em { margin: #{$mpEmSizeWhile}em !important; }

  $mpEmSizeWhile: $mpEmSizeWhile - 0.5;
}
$mpEmSizeWhile: $mpEmSize;
@while $mpEmSizeWhile >= $mpEmMinPadding {
  $mpEmName: str-replace(str-replace(to-string($mpEmSizeWhile), '0.', '.'), '.5', 'half');
  .p#{$mpEmName}em { padding: #{$mpEmSizeWhile}em !important; }

  $mpEmSizeWhile: $mpEmSizeWhile - 0.5;
}
$mpEmSizeWhile: $mpEmSize;

// all sides margin and padding in pixels
@while $mpSizeWhile >= $mpMinMargin {
  .m#{$mpSizeWhile} { margin: #{$mpSizeWhile}px !important; }
  $mpSizeWhile: $mpSizeWhile - 5;
}
$mpSizeWhile: $mpSize;
@while $mpSizeWhile >= $mpMinPadding {
  .p#{$mpSizeWhile} { padding: #{$mpSizeWhile}px !important; }
  $mpSizeWhile: $mpSizeWhile - 5;
}
$mpSizeWhile: $mpSize;

// directional margins and padding
@each $dir in $mpDirections {
  // in ems
  @while $mpEmSizeWhile >= $mpEmMinMargin {
    $mpEmName: str-replace(str-replace(to-string($mpEmSizeWhile), '0.', '.'), '.5', 'half');

    .m#{str_slice($dir, 0, 1)}#{$mpEmName}em, .#{str_slice($dir, 0, 1)}#{$mpEmName}em { margin-#{$dir}: #{$mpEmSizeWhile}em !important; }

    $mpEmSizeWhile: $mpEmSizeWhile - 0.5;
  }
  $mpEmSizeWhile: $mpEmSize;

  @while $mpEmSizeWhile >= $mpEmMinPadding {
    $mpEmName: str-replace(str-replace(to-string($mpEmSizeWhile), '0.', '.'), '.5', 'half');

    .p#{str_slice($dir, 0, 1)}#{$mpEmName}em { padding-#{$dir}: #{$mpEmSizeWhile}em !important; }

    $mpEmSizeWhile: $mpEmSizeWhile - 0.5;
  }
  $mpEmSizeWhile: $mpEmSize;

  // in pixels
  @while $mpSizeWhile >= $mpMinMargin {
    .#{str_slice($dir, 0, 1)}#{$mpSizeWhile} { margin-#{$dir}: #{$mpSizeWhile}px !important; }
    $mpSizeWhile: $mpSizeWhile - 5;
  }
  $mpSizeWhile: $mpSize;
  @while $mpSizeWhile >= $mpMinPadding {
    .p#{str_slice($dir, 0, 1)}#{$mpSizeWhile} { padding-#{$dir}: #{$mpSizeWhile}px !important; }
    $mpSizeWhile: $mpSizeWhile - 5;
  }
  $mpSizeWhile: $mpSize;
}
