/* _l-faux-table.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

// Faux table
.l-faux-table { display: table;
  box-sizing: border-box; }

.l-faux-row { display: table-row;
  box-sizing: border-box; }

.l-faux-cell { display: table-cell;
  box-sizing: border-box; }

.l-table-fixed { table-layout: fixed; }

.l-table-bc { border-collapse: collapse; }

.l-table-padded > .l-faux-cell,
.l-table-padded > .l-faux-row > .l-faux-cell { padding: 0.5rem; }

.l-table-spaced { border-spacing: 0.5rem; }

/* Only for tables with rows (obviously) */
.l-table-rowlines > .l-faux-row > .l-faux-cell { border-bottom: 1px solid $gray-nearwhite; }
.l-table-rowlines > .l-faux-row:last-child > .l-faux-cell { border-bottom-width: 0; }

$cellSizes: (
  xs: 'unnecessary as there is no xs min size but this is a placeholder',
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min
);
$cellKeys: map-keys($cellSizes);
$cellWidths: 8.33333333333%, 16.6666666666%, 25%, 33.3333333333%, 41.6666666666%, 50%, 58.3333333333%, 66.6666666666%, 75%, 83.3333333333%, 91.6666666666%;
// xs needs no media query as it's the first
.l-cell-xs-0 { display: none; }
@for $i from 1 through length($cellWidths) {
  .l-cell-xs-#{$i} { width: nth($cellWidths, $i); }
}
.l-cell-xs-12 { display: block; }
// for sm, md, lg (that's why it's from 2: don't need the xs)
@for $x from 2 through length($cellKeys) {
  $cellSize: nth($cellKeys, $x);
  $prevCellSize: nth($cellKeys, $x - 1);
  @media only screen and (min-width: map-get($cellSizes, $cellSize)) {
    .l-cell-#{$prevCellSize}-0,
    .l-cell-#{$prevCellSize}-12 { display: table-cell; }
    .l-cell-#{$cellSize}-0 { display: none; }
    @for $i from 1 through length($cellWidths) {
      .l-cell-#{$cellSize}-#{$i} { width: nth($cellWidths, $i); }
    }
    .l-cell-#{$cellSize}-12 { display: block; }
  }
}
