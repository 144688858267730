.button {
  cursor: pointer;
  background-color: #4dc704;
  background: linear-gradient(to bottom, #89db5c, #4dc704);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-top: 1px solid #c5edaf;
  border-bottom: 1px solid #286f01;
  border-left: 1px solid #68c734;
  border-right: 1px solid #68c734;
  text-shadow: 0 1px 1px #c5edaf;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #1c4f00;
  font-size: 1.4em;
  margin: 0 0 1em;
  padding: 0.5em 1em;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  width: auto;
  letter-spacing: -0.1em;
  line-height: 1em;
}

.button.tall {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-right: 0.9em;
  padding-left: 0.9em;
  background: transparent linear-gradient(to bottom, $gray-lightest 0%, $gray-nearwhite 100%) repeat
    scroll 0 0 !important;
  border: 1px solid $gray-light !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1) !important;
  color: #707070;
}

.button.wide {
  padding-right: 1.5em;
  padding-left: 1.5em;
}

.button.x-wide {
  padding-right: 2em;
  padding-left: 2em;
}

.button.xx-wide {
  padding-right: 2.5em;
  padding-left: 2.5em;
}

.button.versus {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  background: transparent linear-gradient(to bottom, $gray-lightest 0%, $gray-nearwhite 100%) repeat
    scroll 0 0 !important;
  border: 1px solid $gray-light !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1) !important;
  color: #212121;
  text-transform: none !important;
}

.button.blue {
  cursor: pointer;
  background-color: #2694c2;
  background: linear-gradient(to bottom, #7bcde2, #0685ba);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0.4em;
  border-top: 1px solid #beecf8;
  border-bottom: 1px solid #00549e;
  border-left: 1px solid #0182b8;
  border-right: 1px solid #0787b1;
  text-shadow: 0 1px 1px #beecf8;
  font-weight: bold;
  text-transform: uppercase;
  color: $gray-darkest;
  font-size: 1.4em;
  margin: 0 0 1em;
  padding: 0.5em 1em;
  display: inline-block;
  text-decoration: none;
  letter-spacing: -0.05em;
  text-align: center;
  width: auto;
  line-height: 1em;
  font-family: arial;
}

p .button {
  font-size: 1em;
}

#error-window .button span {
  display: block;
  padding: 0.5em 1em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 50%;
  border-radius: 0;
  font-weight: inherit;
  font-size: inherit;
}

.button.white {
  background: $gray-lightest;
  border-color: $gray-lightest;
  border-radius: 0;
  box-shadow: none;
  color: #cb333b;
  text-shadow: none;
  vertical-align: middle;
  filter: none;
}

.button.cancel {
  // this button is orange, and has no icon
  padding: 0;
  background-color: #e28603;
  background: linear-gradient(to bottom, #eeb768, #e28603);
  color: #773a0b;
  text-shadow: 0 1px 1px #eeb768;
  border-top: 1px solid #f4cf8d;
  border-bottom: 1px solid #773a0b;
  border-left: 1px solid $gray-light;
  border-right: 1px solid $gray-light;
}

.button.disabled {
  // these buttons are grey,button.plus.disabled has an icon
  background-color: #dedede;
  background: linear-gradient(to bottom, #dedede, #cdcdcd);
  color: #b5b5b5;
  text-shadow: 0 1px 1px $gray-lightest;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #999;
  border-left: 1px solid $gray-light;
  border-right: 1px solid $gray-light;
}

.button-group.acc-icons .button {
  display: block;
  margin: 0 10px 10px;
  padding: 0;
  border-radius: 0.3em;
  font-size: 1.4em;
  text-align: left;
  letter-spacing: 0;
}

.btn {
  border: 1px solid $gray-light;
  color: $gray;
  text-decoration: none;
}

