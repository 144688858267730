.nue-button {
  appearance: none;
  background-color: $gray-lightest;
  background-image: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: $gray-dark;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4em;
  line-height: normal;
  margin: 0 0 1em;
  padding: 0.714em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;

  &:hover,
  &:focus {
    background-color: darken($gray-lightest, 5%);
  }

  &:active {
    background-color: lighten($gray-lightest, 10%);
  }
}

.nue-button.nue-primary {
  background-color: $tertiary-green;
  color: $gray-lightest;

  &:hover,
  &:focus {
    background-color: darken($tertiary-green, 5%);
  }

  &:active {
    background-color: lighten($tertiary-green, 10%);
  }
}

.nue-button.nue-secondary {
  border: 1px solid $gray-lighter;
  color: $gray-dark;
}

.nue-button.nue-info {
  background-color: $brand-info;
  color: $gray-lightest;

  &:hover,
  &:focus {
    background-color: darken($brand-info, 5%);
  }

  &:active {
    background-color: lighten($brand-info, 10%);
  }
}

.nue-button.nue-warning {
  background-color: $brand-warning;
  color: $gray-lightest;

  &:hover,
  &:focus {
    background-color: darken($brand-warning, 5%);
  }

  &:active {
    background-color: lighten($brand-warning, 10%);
  }
}

.nue-button.nue-danger {
  background-color: $brand-danger;
  color: $gray-lightest;

  &:hover,
  &:focus {
    background-color: darken($brand-danger, 5%);
  }

  &:active {
    background-color: lighten($brand-danger, 10%);
  }
}

.nue-button.nue-brand-primary {
  background-color: $primary-purple;
  color: $gray-lightest;

  &:hover,
  &:focus {
    background-color: darken($primary-purple, 5%);
  }

  &:active {
    background-color: lighten($primary-purple, 10%);
  }
}

.nue-button.nue-brand-secondary {
  background-color: $primary-purple-start;
  color: $gray-lightest;

  &:hover,
  &:focus {
    background-color: darken($primary-purple-start, 5%);
  }

  &:active {
    background-color: lighten($primary-purple-start, 10%);
  }
}

.nue-button.disabled,
.nue-button.nue-disabled,
.nue-button[disabled] {
  background-color: $gray-light;
  color: $gray-lighter;
  cursor: not-allowed;

  &:hover,
  &:focus {
    background-color: darken($gray-light, 5%);
  }

  &:active {
    background-color: lighten($gray-light, 10%);
  }
}

.nue-button.selected,
.nue-button.nue-selected {
  background-color: $gray-dark;
  color: $gray-lighter;
  cursor: not-allowed;

  &:hover,
  &:focus {
    background-color: $gray-dark;
  }

  &:active {
    background-color: $gray-dark;
  }
}

.nue-button.nue-block {
  display: block;
  width: 100%;
}

.nue-button.nue-small {
  font-size: 1.2rem;
}

.nue-button.nue-standard {
  height: 4rem;
  padding: 0 1em;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 4rem;
  text-align: center;
  text-transform: none;
}

.nue-button.nue-capitalized {
  text-transform: none;
}

.button-group {
  .nue-button {
    &:first-child {
      margin-left: 0;
    }

  }

  .nue-button + .nue-button {
    margin-left: 0.5rem;
  }
}

