/// Load core variables and mixins
@import 'rwwaCss/bootstrap/variables';
@import 'rwwaCss/bootstrap/mixins';

// Used in 'Find TAB location'
.watermark-textbox {
  color: #ada9b1;
}

#map_container p,
#map_canvas p {
  font-size: 11px;
}

#map_container label {
  text-shadow: none;
}

#map_container {
  width: 100%;
  float: left;
  position: relative;
}

#map_canvas {
  font-size: 12px;
  padding: 0;
}

.map_default {
  font-size: 12px;
  width: 100%;
}

#currentLocationWrapper {
  float: left;
  border-right: 1px #999 dotted;
  width: 45%;
  padding: 0 0.4em;
}

#postcode {
  float: right;
  width: 50%;
  padding-left: 0.4em;
}

.or {
  float: left;
  width: 145px;
  text-align: center;
  margin-top: 13px;
  font-size: 20px;
}

#sl1-purple {
  background: url('../images/icons/sl1-purple.png') no-repeat;
}

#sl2-yellow {
  background: url('../images/icons/sl2-yellow.png') no-repeat;
}

#sl3-red {
  background: url('../images/icons/sl3-red.png') no-repeat;
}

#map_canvas input,
#map_container input {
  transition: none;
}

#map_canvas input:focus,
#map_container input:focus {
  transition: none;
}

input#address {
  float: left;
  background: #999;
  border: 0;
  border-radius: 4px 0 0 4px;
  height: 1.5em;
  padding: 0 0 0 0.4em;
  background: #d4d0c3;
  background: linear-gradient(to bottom, #d4d0c3 0%, #e5e3dd 100%);
  background: -webkit-gradient(linear,
  left top,
  left bottom,
  color-stop(0%, #d4d0c3),
  color-stop(100%, #e5e3dd));
  background: linear-gradient(to bottom, #d4d0c3 0%, #e5e3dd 100%);
  background: linear-gradient(to bottom, #d4d0c3 0%, #e5e3dd 100%);
  background: linear-gradient(to bottom, #d4d0c3 0%, #e5e3dd 100%);
  background: linear-gradient(to bottom, #d4d0c3 0%, #e5e3dd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d4d0c3', endColorstr='#e5e3dd', GradientType=0);
  box-shadow: inset 0 1px 5px #b7b5ae;
  width: 65%;
  margin-right: 0.2em;
  font-size: 1.4em;
}

input.go_button {
  border-radius: 4px;
  display: block;
  height: 1.5em;
  border: 0;
  padding: 0 0.7em;
  font-weight: bold;
  margin: 0;
  text-transform: none;
}

#currentLocationWrapper,
#postcode,
.or {
  margin-top: 5px;
}

.or {
  width: 25px;
  font-size: 15px;
}

#postcode {
  float: left;
}

span.sl1-purple,
span.sl2-yellow,
span.sl3-red {
  background-size: 90%;
  height: 1.5em;
  width: 0.7em;
  float: left;
  margin-right: 0.5em;
}

.sl1-purple {
  background: url('../images/icons/sl1-purple.png') no-repeat;
}

.sl2-yellow {
  background: url('../images/icons/sl2-yellow.png') no-repeat;
}

.sl3-red {
  background: url('../images/icons/sl3-red.png') no-repeat;
}

.tabPin {
  height: 3em;
  width: 1.5em;
  display: block;
  float: left;
  margin-left: 0;
  margin-right: 0.5em;
  margin-top: 1em;
}

ol#tabTypeSelectionList {
  list-style-type: none;
  margin: 0 auto;
  width: 29em;
  padding: 0;
}

.TabType {
  width: 4.5em;
  margin-left: 5px;
  font-size: 1em;
  display: table-cell;
  vertical-align: middle;
}

.pinListItem {
  height: 40px;
  line-height: 1em;
  float: left;
  width: 31%;
  padding-left: 0.6em;
}

#go_button {
  border-radius: 0 4px 4px 0;
}

#currentLocation {
  width: 100%;
}

.regular-checkbox {
  font-size: 1em;
  margin-top: 1.5em;
}

#tabTypeSelectionContainer,
.tabTypeSelector {
  height: 4.5em;
}

.tabTypeSelector {
  float: left;
  display: table;
  width: 75%;
}

#tabTypeSelectionContainer {
  background: #000 -webkit-gradient(linear, left bottom, left top, color-stop(0, #000), color-stop(1, #333));
  border-top: 1px solid #474747;
  border-bottom: 1px solid #000;
  background-color: #222;
  color: #fff;
}

.location-selection {
  margin-top: -10px;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  height: 3.5em;
  padding-top: 1em;
}

.welcomeIcon {
  width: 10px;
  height: 20px;
}

#infoWindowContent {
  font-size: 12px;
  line-height: 1.5;
}
