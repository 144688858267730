// TT-ICONS -- USES ICON FONT

.icon-inline {
  background-size: 100% !important;
  display: inline-block;
  height: 1em;
  width: 1em;

  &.icon-1\.5 {
    height: 1.5em;
    width: 1.5em;
  }

  &.icon-2 {
    height: 2em;
    width: 2em;
  }
}

.tticon::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'tticons' !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: relative;
  text-transform: none !important;
  top: 1px;
}

.tticon.tticon-defaultspecial::before {
  content: '\153';
}

.tticon.tticon-tab::before,
.tticon.tticon-defaultsport::before {
  content: '\31';
}

.tticon.tticon-tabtouch::before {
  content: '\28';
}

.tticon.tticon-tabtouchstacked::before {
  content: '\29';
}

.tticon.tticon-racinggen::before {
  content: '\32';
}

.tticon.tticon-racing::before,
.tticon.tticon-sport-hr::before {
  content: '\33';
}

.tticon.tticon-racinghorse::before {
  content: '\7e';
}

.tticon.tticon-trot::before,
.tticon.tticon-sport-ha::before {
  content: '\34';
}

.tticon.tticon-dog::before,
.tticon.tticon-sport-gr::before {
  content: '\35';
}

.tticon.tticon-all::before {
  content: '\36';
}

.tticon.tticon-favnos::before {
  content: '\23';
}

.tticon.tticon-mystery::before {
  content: '\3f';
}

.tticon.tticon-jackpot::before {
  content: '\37';
}

.tticon.tticon-next15::before {
  content: '\38';
}

.tticon.tticon-next10::before {
  content: '\30';
}

.tticon.tticon-nextrace::before {
  content: '\39';
}

.tticon.tticon-arrowleft::before {
  content: '\3c';
}

.tticon.tticon-arrowright::before {
  content: '\3e';
}

.tticon.tticon-arrowup::before {
  content: '\5e';
}

.tticon.tticon-arrowdown::before {
  content: '\76';
}

.tticon.tticon-arrowleftsolid::before {
  content: '\5b';
}

.tticon.tticon-arrowrightsolid::before {
  content: '\5d';
}

.tticon.tticon-arrowupsolid::before {
  content: '\7b';
}

.tticon.tticon-arrowdownsolid::before {
  content: '\7d';
}

.tticon.tticon-cross::before {
  content: '\78';
}

.tticon.tticon-tick::before {
  content: '\fb';
}

.tticon.tticon-info::before {
  content: '\69';
}

.tticon.tticon-exclaim::before {
  content: '\21';
}

.tticon.tticon-crossinvert::before {
  content: '\58';
}

.tticon.tticon-tickinvert::before {
  content: '\fc';
}

.tticon.tticon-infoinvert::before {
  content: '\49';
}

.tticon.tticon-exclaiminvert::before {
  content: '\fd';
}

.tticon.tticon-flagresults::before {
  content: '\72';
}

.tticon.tticon-binoculars::before {
  content: '\62';
}

.tticon.tticon-play::before {
  content: '\70';
}

.tticon.tticon-pause::before {
  content: '\50';
}

.tticon.tticon-stop::before {
  content: '\161';
}

.tticon.tticon-head::before {
  content: '\68';
}

.tticon.tticon-star::before {
  content: '\2a';
}

.tticon.tticon-plus::before {
  content: '\2b';
}

.tticon.tticon-minus::before {
  content: '\2d';
}

.tticon.tticon-refresh::before {
  content: '\52';
}

.tticon.tticon-telephone::before {
  content: '\74';
}

.tticon.tticon-infoinvertround::before {
  content: '\ec';
}

.tticon.tticon-blackbook::before {
  content: '\42';
}

.tticon.tticon-offer::before {
  content: '\6f';
}

.tticon.tticon-link::before {
  content: '\6c';
}

.tticon.tticon-linkhori::before {
  content: '\4c';
}

.tticon.tticon-hatgrad::before {
  content: '\48';
}

.tticon.tticon-finvert::before {
  content: '\46';
}

.tticon.tticon-mysteryinvert::before {
  content: '\bf';
}

.tticon.tticon-cog::before {
  content: '\63';
}

.tticon.tticon-bartriple::before {
  content: '\3d';
}

.tticon.tticon-plusminus::before {
  content: '\b1';
}

.tticon.tticon-e::before {
  content: '\45';
}

.tticon.tticon-dogeartop::before {
  content: '\b4';
}

.tticon.tticon-dogearbottom::before {
  content: '\b8';
}

.tticon.tticon-arrowthinleft::before {
  content: '\bc';
}

.tticon.tticon-arrowthinright::before {
  content: '\bd';
}

.tticon.tticon-arrowthindblleft::before {
  content: '\ab';
}

.tticon.tticon-arrowthindblright::before {
  content: '\bb';
}

.tticon.tticon-magnifier::before {
  content: '\2e';
}

.tticon.tticon-printer::before {
  content: '\fe';
}

.tticon.tticon-futurefinalfield::before {
  content: '\192';
}

.tticon.tticon-jockeychallenge::before,
.tticon.tticon-sport-hrc::before {
  content: '\6a';
}

.tticon.tticon-featureraces::before,
.tticon.tticon-sport-fr::before {
  content: '\df';
}

.tticon.tticon-racingspecials::before,
.tticon.tticon-sport-sr::before {
  content: '\73';
}

.tticon.tticon-boxchallenge::before,
.tticon.tticon-sport-grc::before {
  content: '\43';
}

.tticon.tticon-international::before {
  content: '\d4';
}

.tticon.tticon-pencil::before {
  content: '\d5';
}

.tticon.tticon-tophat::before {
  content: '\af';
}

.tticon.tticon-jockeyhat::before {
  content: '\4a';
}

.tticon.tticon-pin::before {
  content: '\d6';
}

.tticon.tticon-pinlbs::before {
  content: '\d8';
}

.tticon.tticon-apple::before {
  content: '\d9';
}

.tticon.tticon-android::before {
  content: '\da';
}

.tticon.tticon-weathersunny::before {
  content: '\db';
}

.tticon.tticon-weatherfine::before {
  content: '\db';
}

.tticon.tticon-weatherovercast::before,
.tticon.tticon-weathercloudy::before {
  content: '\dc';
}

.tticon.tticon-weathershowery::before {
  content: '\de';
}

.tticon.tticon-weathercloudypartly::before {
  content: '\dc';
}

.tticon.tticon-weathercloudy::before {
  content: '\dd';
}

.tticon.tticon-weatherraining::before {
  content: '\de';
}

.tticon.tticon-weatherunknown::before {
  content: '\fa';
}

.tticon.tticon-sports::before {
  content: '\f0';
}

.tticon.tticon-television::before {
  content: '\f1';
}

.tticon.tticon-microphone::before {
  content: '\f2';
}

.tticon.tticon-facebook::before {
  content: '\f3';
}

.tticon.tticon-twitter::before {
  content: '\f4';
}

.tticon.tticon-track::before {
  content: '\f5';
}

.tticon.tticon-bulb::before {
  content: '\f6';
}

.tticon.tticon-ruler::before {
  content: '\f8';
}

.tticon.tticon-clock::before {
  content: '\f9';
}

.tticon.tticon-house::before {
  content: '\ff';
}

.tticon.tticon-backspace::before {
  content: '\152';
}

.tticon.tticon-special::before {
  content: '\153';
}

.tticon.tticon-blog::before {
  content: '\160';
}

.tticon.tticon-extlink::before {
  content: '\2014';
}

.tticon.tticon-moneynote::before {
  content: '\24';
}

.tticon.tticon-tips::before {
  content: '\54';
}

.tticon.tticon-ellipsis::before {
  content: '\2026';
}

.tticon.tticon-nay::before {
  content: '\2600';
}

.tticon.tticon-calendar::before {
  content: '\2601';
}

.tticon.tticon-clockarrowfwd::before {
  content: '\2602';
}

.tticon.tticon-arrowdbldirshori::before {
  content: '\2603';
}

.tticon.tticon-totesports::before,
.tticon.tticon-sport-esp::before {
  content: '\2604';
}

.tticon.tticon-visa::before {
  content: '\2605';
}

.tticon.tticon-mastercard::before {
  content: '\2606';
}

.tticon.tticon-summary::before {
  content: '\2607';
}

.tticon.tticon-bin::before {
  content: '\2608';
}

// Sports

.tticon.tticon-alpinesports::before,
.tticon.tticon-sport-alp::before {
  content: '\41';
}

.tticon.tticon-athletics::before,
.tticon.tticon-sport-atl::before {
  content: '\60';
}

.tticon.tticon-badminton,
.tticon.tticon-sport-bdm {
  &::before {
    content: '\b6';
  }
}

.tticon.tticon-baseball::before,
.tticon.tticon-sport-bsb::before {
  content: '\ed';
}

.tticon.tticon-basketball::before,
.tticon.tticon-sport-bb::before {
  content: '\e0';
}

.tticon.tticon-boxing::before,
.tticon.tticon-sport-box::before {
  content: '\e1';
}

.tticon.tticon-commonwealthgames::before,
.tticon.tticon-sport-cwg::before,
.tticon.tticon-sport-oly::before {
  content: '\e7';
}

.tticon.tticon-cricket::before,
.tticon.tticon-sport-crt::before {
  content: '\c7';
}

.tticon.tticon-cycling::before,
.tticon.tticon-sport-cyc::before {
  content: '\a9';
}

.tticon.tticon-darts::before,
.tticon.tticon-sport-drt::before {
  content: '\64';
}

.tticon.tticon-elections::before,
.tticon.tticon-sport-elt::before {
  content: '\65';
}

.tticon.tticon-entertainment::before,
.tticon.tticon-sport-ent::before {
  content: '\c8';
}

.tticon.tticon-financial::before,
.tticon.tticon-sport-fin::before {
  content: '\c0';
}

.tticon.tticon-footballdark::before,
.tticon.tticon-sport-fb::before {
  content: '\c3';
}

.tticon.tticon-footballlight,
.tticon.tticon-sport-fbl {
  &::before {
    content: '\c2';
  }
}

.tticon.tticon-footballus::before,
.tticon.tticon-sport-af::before {
  content: '\c4';
}

.tticon.tticon-footballau::before,
.tticon.tticon-sport-ar::before {
  content: '\c5';
}

.tticon.tticon-footballca::before,
.tticon.tticon-sport-caf::before {
  content: '\c6';
}

.tticon.tticon-footballepl,
.tticon.tticon-sport-fbe {
  &::before {
    content: '\e8';
  }
}

.tticon.tticon-footballgaelic::before,
.tticon.tticon-sport-gfb::before {
  content: '\e2';
}

.tticon.tticon-footballworldcup::before,
.tticon.tticon-sport-wcp::before {
  content: '\77';
}

.tticon.tticon-friendlies::before,
.tticon.tticon-sport-frd::before {
  content: '\e4';
}

.tticon.tticon-golf::before,
.tticon.tticon-sport-glf::before {
  content: '\e5';
}

.tticon.tticon-handball,
.tticon.tticon-sport-hdb {
  &::before {
    content: '\e6';
  }
}

.tticon.tticon-hockey::before,
.tticon.tticon-sport-fhk::before {
  content: '\c9';
}

.tticon.tticon-hockeyice::before,
.tticon.tticon-sport-ice::before {
  content: '\ca';
}

.tticon.tticon-live::before,
.tticon.tticon-sport-lvb::before {
  content: '\ae';
}

.tticon.tticon-martialarts::before,
.tticon.tticon-sport-mma::before {
  content: '\2020';
}

.tticon.tticon-motorsport::before,
.tticon.tticon-sport-mtr::before {
  content: '\2013';
}

.tticon.tticon-netball::before,
.tticon.tticon-sport-net::before {
  content: '\6e';
}

.tticon.tticon-rugby::before,
.tticon.tticon-sport-rgl::before {
  content: '\ea';
}

.tticon.tticon-rugbyunion::before,
.tticon.tticon-sport-rgu::before {
  content: '\75';
}

.tticon.tticon-snooker::before,
.tticon.tticon-sport-snk::before {
  content: '\53';
}

.tticon.tticon-surfironman::before,
.tticon.tticon-sport-sri::before {
  content: '\cd';
}

.tticon.tticon-surfing::before,
.tticon.tticon-sport-wrf::before {
  content: '\ce';
}

.tticon.tticon-swimming::before,
.tticon.tticon-sport-swm::before {
  content: '\cf';
}

.tticon.tticon-tabletennis::before,
.tticon.tticon-sport-ttn::before {
  content: '\ee';
}

.tticon.tticon-tennis::before,
.tticon.tticon-sport-tn::before {
  content: '\ef';
}

.tticon.tticon-volleyball::before,
.tticon.tticon-sport-vlb::before {
  content: '\d0';
}

.tticon.tticon-wintersports::before,
.tticon.tticon-sport-wsp::before {
  content: '\d3';
}

.tticon.tticon-yachting::before,
.tticon.tticon-sport-yct::before {
  content: '\79';
}

.tticon.tticon-special.special-bright {
  position: relative;
  color: map-deep-get($brandacc, 'ter', 'light', 'bg') !important;

  &::before {
    z-index: 2;
  }

  &::after {
    background-color: $gray-lightest;
    content: '';
    display: inline-block;
    height: 0.65em;
    left: 0;
    position: absolute;
    transform: translate(27%, 29%) rotate(45deg);
    width: 0.65em;
    z-index: 1;
  }
}

.tticon.tticon-jackpot.jackpot-bright {
  color: map-deep-get($brandacc, 'ui', 'info', 'bg') !important;
}

.tticon.tticon-star.star-bright {
  color: map-deep-get($brandacc, 'ter', 'light', 'bg') !important;
}
