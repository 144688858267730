@import 'rwwaCss/bootstrap/variables';
@import 'rwwaCss/bootstrap/mixins';

.page-heading {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  background-color: $gray-darkest;
  color: $gray-lightest;

  a {
    color: $gray-lightest;
    text-decoration: none;
  }
}

.refresh-button {
  display: inline-block;
  line-height: 1;
}

.page-heading-copy {
  display: inline;
}

.cell-icon {
  width: 3em;
}

.cell-icon-4half {
  width: 4.5rem;
}

.cell-icon-5 {
  width: 5rem;
}

.cell-icon-5half {
  width: 5.5rem;
}

.icon-pool {
  background-color: $gray-lightest;
  padding: 0.2rem 0.5rem;
  border: 1px solid $gray-dark;
  color: $gray-dark;

  &.icon-pool-int {
    border-color: map-deep-get($brandacc, 'ui', 'fail', 'bg');
    color: map-deep-get($brandacc, 'ui', 'fail', 'bg');
  }
}

// Rider
.rider.rider-change,
.rider-change {
  padding: 0 2px;
  background-color: #cd92c8;
}

