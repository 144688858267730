/* _widths.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

.w100 { width: 100% !important; }
.w75 { width: 75% !important; }
.w70 { width: 70% !important; }
.w65 { width: 65% !important; }
.w50 { width: 50% !important; }
.w40 { width: 40% !important; }
.w30 { width: 30% !important; }
.w25 { width: 25% !important; }
.w15 { width: 15% !important; }
.w10 { width: 10% !important; }
