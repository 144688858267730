// TODO: Remove when legacy deposit is removed (AHFM slice JS)

.paymentMethodList__iconArrow {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 30px;
  color: #333;
  z-index: 1000;
  cursor: pointer;
}

.paymentMethodList__paymentMethod--disabled { opacity: 0.5; }

.paymentMethodList__paymentMethod--selected { background-color: #fdffbe; }

.paymentrow {
  .borderedrow { border-top: 1px solid #d0d0d0; }

  &:last-child .borderedrow { border-bottom: 1px solid #d0d0d0; }
}

/// Note: When viewing the braintree 3d secure content in sandbox mode, the iframe, for some reason, doesn't allow the user
/// to scroll the page when viewing it on a device in landscape rotation.

.deposit-legacy__threeDSecure {
  background-color: $gray-lightest;
  // Defined in MOBI is the normal height of the "Deposit" header which is 50px. Larger device is 80px.
  // This is offset from the full height of the viewport to get the height that we can use for 3d secure content wrapper.
  min-height: calc(100vh - 50px);

  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 80px);
  }
}

.deposit-legacy__threeDSecure-info {
  background-color: $gray-lighter;
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem 0.5rem;
  text-align: center;
}

.deposit-legacy__bankIFrame-container {
  overflow: auto;

  iframe {
    min-height: 400px; // Matches Braintree iframe height and 400px seems to be some kind of agreed standard between vendors.
    vertical-align: top;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .deposit-legacy__paymentLogo {
    height: 5em;
  }
}

// =========
// NoticeBox
// =========

.notice-box {
  background-color: $gray-lightest;
  border-color: $gray-light;
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  margin: 1em;
  padding: 1em;

  &.notice-box--borderless {
    border-color: transparent;
  }

  &.notice-box--inline {
    margin: 0;
  }
}

.notice-box__icon {
  left: 2em;
  position: absolute;
  top: 1.25em;

  .icon {
    $icon-size: 3em;

    background-size: $icon-size;
    height: $icon-size;
    width: $icon-size;
  }
}

.notice-box--exclaim { border-color: #df7227; }
.notice-box--error { border-color: #bf2324; }

.notice-box__copy {
  font-size: 1.2em;
  line-height: 1.3em;
  margin-left: 4em;
  text-align: left;

  &:first-child { margin-left: 0; }
  a { color: $secondary-purple; }

  p {
    // Override Mobi styles
    text-align: left;

    // Override Mobi styles - remove font on p in core
    &:not(:first-child) {
      font-size: 1em; // Seperate because title is bigger than rest of copy
    }

    // Reduce the margin of the last P tag in the copy for balance
    &:last-of-type {
      margin-bottom: 0.5em;
    }

    // When there is only a title, don't put margin on the bottom
    &:only-of-type {
      margin-bottom: 0;
    }
  }
}

.notice-box__title {
  $font-size-title: 1.2em;
  $title-height: 2.315em; // 40px/80px equiv on phone/tablet

  align-items: center;
  display: flex;
  font-size: $font-size-title;
  line-height: 1.3em;
  margin-bottom: 0;
  margin-top: 0;
  min-height: $title-height;
  text-align: left;
}

// =====
// Icons
// =====

.icon {
  $icon-size: 1rem;
  background-repeat: no-repeat;
  background-size: $icon-size;
  display: inline-block;
  font-size: $icon-size;
  height: $icon-size;
}

.icon--exclaim {
  background-image: url('../images/deposit-legacy/icon-exclaim.svg');
}

.icon--error {
  background-image: url('../images/deposit-legacy/icon-error.svg');
}

.icon--success {
  background-image: url('../images/deposit-legacy/icon-success.svg');
}
