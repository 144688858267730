span.arrow.down {
  background-image: url('../images/content/loss-arrow.png');
  color: #f30;
}

span.arrow.up {
  background-image: url('../images/content/gain-arrow.png');
  color: #84c447;
}

span.arrow.down,
span.arrow.up {
  display: inline-block;
  width: 9px;
  height: 5px;
  overflow: hidden;
  margin-top: -2px;
  text-indent: -10000px;
  text-decoration: none;
  vertical-align: middle;
}
