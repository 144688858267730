.checked-element {
  display: inline-block;
  width: 2.6em;

  input {
    position: absolute;
    left: -9999px;
  }

  label {
    width: 2.5em;
    height: 2.5em;
    display: block;
    position: relative;
    border: 1px solid $gray-light;
    color: $gray-darker;
    background-color: $gray-lightest;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    overflow: hidden;
    font-size: 1em;
    text-align: center;
    line-height: 2.5em;
    -webkit-tap-highlight-color: transparent;

    span {
      font-size: 2.5em;
      display: none;
      padding-bottom: 0.5em;
      margin-top: -2px;
    }
  }

  label.active span {
    display: block;
    background-color: $gray-darkest;
    color: $gray-lightest;
    pointer-events: none;
    position: absolute;
  }
}

.checked-element.checkradio.index0 label {
  border-radius: 50%;
  display: inline-block;
  border: 1px solid $gray-light;
  background-color: $gray-lightest;
  box-shadow: inset 0 0 0 4px $gray-lightest;
  text-indent: -9999px;
  box-sizing: border-box;
  right: 0.2em;
}

.checked-element.checkradio.index0 input:checked + label {
  background-color: $gray-darkest;
  border: 2px solid $gray-darkest;
}

.checked-element input:checked + label {
  background-color: $gray-darkest;
  border: 1px solid $gray-darkest;
  color: $gray-lightest;
}
