/* _colors.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

// Brand Colours
$colour-keys: map-keys($brand);
$i: length($colour-keys);

@while $i > 0 {
  $section-keys: map-keys(map-get($brand, nth($colour-keys, $i)));
  $x: length($section-keys);

  @while $x > 0 {
    .bg-#{nth($colour-keys, $i)}-#{nth($section-keys, $x)} {
      background-color: map-deep-get($brand, nth($colour-keys, $i), nth($section-keys, $x)) !important;
    }
    .fg-#{nth($colour-keys, $i)}-#{nth($section-keys, $x)} {
      color: map-deep-get($brand, nth($colour-keys, $i), nth($section-keys, $x)) !important;
    }

    $x: $x - 1;
  }

  $i: $i - 1;
}
