#KambiBC {
  display: block;

  #NavigationSectionWidget {
    .web-component {
      &__title {
        display: block;
        margin: 0;
        color: #f49004;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 30px;
        padding: 0 11px 0 14px;
      }

      &__unordered {
        padding: 0;
        margin: 0;
      }

      &__list-item {
        list-style-type: none;

        &:hover {
          background-color: #5e347f;
        }
      }

      &__link {
        color: #fff;
        font-size: 14px;
        padding: 4px 14px;
        align-items: center;
        border-left: 2px solid transparent;
        display: flex;
        font-family: Arial;
        justify-content: space-between;
        text-decoration: none;
        transition: color 0.2s ease-out, background 0.2s ease-out,border-color 0.2s ease-out;
      }
    }
  }

  &.mobi__kambi-hide-element {
    display: none;
  }
}
