/// Load core variables and mixins
@import 'rwwaCss/bootstrap/variables';
@import 'rwwaCss/bootstrap/mixins';

.pc-notice {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
}

.pc-notice .dialog {
  width: 32em;
  background: #fff url('../images/content/ribbons.png') no-repeat right bottom;
  background-size: 50em 13.3em;
  position: absolute;
  left: 50%;
  margin-left: -20em;
  padding: 4em 4em 10em;
  top: 4em;
}

.pc-notice .dialog a.button {
  padding: 0 2em;
  line-height: 2.857em;
  width: 60%;
}

.remember {
  text-align: center;
  margin-top: 2em;
  font-size: 1.4em;
}

.remember label {
  vertical-align: top;
  color: #666;
}

.pc-notice .cross {
  width: 1em;
  height: 1em;
  background: url('../images/vector/cross.svg') 0 0 no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 1em;
  top: 1em;
  text-indent: -999px;
  overflow: hidden;
  cursor: pointer;
}
