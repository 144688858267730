/* _text.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

.tdu {
  text-decoration: underline !important;

  &.tdch:focus,
  &.tdch:hover,
  &.tdch:active {
    text-decoration: none !important;
  }
}

.tdn {
  text-decoration: none !important;

  &.tdch:focus,
  &.tdch:hover,
  &.tdch:active {
    text-decoration: underline !important;
  }
}

.text-upper { text-transform: uppercase !important; }

.text-lower { text-transform: lowercase !important; }

.text-orig { text-transform: none !important; }

.ls0 { letter-spacing: 0 !important; }

.faux-strong { font-weight: bold !important; }

.faux-em { font-style: italic !important; }
