.arrow_box.open {
  position: absolute;
  background: #713d90;
  bottom: 0;
  left: 20px;
  z-index: 1;
}

.arrow_box.open::after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(113, 61, 144, 0);
  border-top-color: #713d90;
  border-width: 8px;
  margin-left: -8px;
}
