@import 'rwwaCss/bootstrap/variables';
@import 'rwwaCss/bootstrap/mixins';

@import 'modules/favorite-icon';
@import 'modules/arrows';

h3 .tticon.tticon-special {
  float: right;
  margin-top: 0.08em;
  margin-right: 0.08em;
  font-size: 2.0833333333em;
}

button:focus {
  outline: 0;
}

@import 'modules/race-replay';
@import 'modules/flat.runners-list';
@import 'modules/resulted-runners';
@import 'modules/flat.field-selection';
@import 'modules/multi-type-selection';
@import 'modules/arrow-box';
@import 'modules/meeting-view-option';
@import 'modules/flat.column-header';
@import 'modules/selection-check-boxes/base';
@import 'modules/selection-check-boxes/single';
@import 'modules/selection-check-boxes/duo';
@import 'modules/selection-check-boxes/trifecta';
@import 'modules/selection-check-boxes/quaddie';
@import 'modules/selection-check-boxes/checked-element';
@import 'modules/buttons';
@import 'modules/icons';

@import 'modules/deposit-legacy';

