/* _position.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

.poss { position: static !important; }
.posa { position: absolute !important; }
.posf { position: fixed !important; }
.posr { position: relative !important; }
