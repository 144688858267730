.flat-field-section {
  background-color: $gray-lightest;
  border-bottom: 1px solid #e0e0e0;
  padding: 1em 0.75em;
  display: block;
  overflow: auto;

  .column-container {
    display: table-header-group;
    float: right;
    margin: 0 0 0.5em;

    .column-header {
      text-align: center;
      display: table-cell;
      padding: 0 0.15em;
      width: 3.1em;
      color: #512d6d;
      font-weight: bold;
    }
  }

  .flat-field-sameas-holder {
    min-width: 20em;
  }

  .checked-element-holder {
    text-align: right;
  }

  .checked-element {
    display: inline-block;
    width: 2.6em;
    font-size: 1.2em;
    list-style: outside none none;
    margin: 0 0 0 0.25em;
    float: right;
  }

  .flat-field-section-label {
    display: block;
    text-align: left;
    padding: 0.7em 0;
    min-width: 10em;
    float: left;
    color: #666;
    font-size: 1.2em;
  }
}

#multi-leg .flat-field-section {
  box-shadow: 0 1px 3px rgba($gray-darkest, 0.12), 0 1px 2px rgba($gray-darkest, 0.24) !important;
}

#allup .flat-field-section {
  box-shadow: 0 1px 3px rgba($gray-darkest, 0.12), 0 1px 2px rgba($gray-darkest, 0.24) !important;
}
