@import 'rwwaCss/bootstrap/variables';

// Override the default of 10px
$base-font-size: 16px;
$rem-ratio: calc($base-font-size / 1rem);

@import 'rwwaCss/bootstrap/mixins';

@include media('screen', 'width>=small') {
  h3.toggle.open .arrow {
    margin-top: 1em; // 19.2000007629395/19.2000007629395
  }

  #main-container .numpad a {
    font-size: 1.5em; // 33/22

    &.key-backspace .tticon {
      padding-top: 0.22em; // 11/50
      padding-bottom: 0.42em; // 21/50
      font-size: 1.5151515151em; // 50/33
    }
  }

  // ul li --> _legacy-globals.scss
  ul.display-list li {
    font-size: 1.375em; // 22/16
  }

  h3 span.guaranteed {
    background-image: url('../images/icons/ipad/guaranteed-minimum.png') !important;
  }

  h3 span.fixed {
    background-image: url('../images/icons/ipad/fixed.png') !important;
  }

  // Result
  .pay {
    margin-right: 10px;
    font-size: 15px;
  }
}
