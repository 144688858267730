.video-container {
  background: #666;
  font-size: initial;

  header {
    margin: 0 1em;
    clear: both;
    color: $gray-lightest;
    line-height: 2em;
    text-shadow: none;

    .close-video {
      cursor: pointer;
      float: right;
      font-size: 0.8em;
      display: block;
      color: $gray-lightest;
      text-decoration: underline;
      height: 2.5em;
      position: relative;

      i::before {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .separator {
      &::before {
        content: '|';
      }
    }

    .race-location {
      color: #fff;
      font-weight: bold;
    }

    .race-date {
      color: $gray-lighter;

      &:nth-child(2) {
        padding-left: 1em;
        margin-left: 1em;
        border-left: $gray-lighter 1px solid;
      }
    }

    a {
      margin: 0;
      padding: 0 !important;
    }
  }
}

// Race Replay Denied

.reason-holder {
  text-align: center;
}

.reason-container {
  background-color: #d9d9d9;
  margin: 1em;
  padding: 2em 1em;
  font-size: 0.75em;
  text-align: center;
  display: inline-block;

  h5 {
    font-size: 1.2em;
    text-align: center;
    margin: 1em 0.75em 1.2em;
    text-shadow: none;
  }

  .replay-entitlement-hidden-reason-text {
    padding: 1em 2em 0;
    text-shadow: none;
  }
}

