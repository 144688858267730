/* _card.scss */

//
// Load core variables and mixins
// --------------------------------------------------

@import '../bootstrap/variables';
@import '../bootstrap/mixins';

.card {
  background-color: $gray-lightest;
  box-shadow: 0 -1px 0 0 $gray-lighter, 0 0 2px 0 rgba($gray-darkest, 0.12), 0 2px 2px 0 rgba($gray-darkest, 0.24); // Deepesh's chosen box-shadow
}
