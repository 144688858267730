@import 'rwwaCss/bootstrap/variables';
@import 'rwwaCss/bootstrap/mixins';

@import 'base/fonts';

html,
body {
  margin: 0;
  padding: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-text-size-adjust: none;
}

html {
  min-height: 100%;
  font: normal 62.5%/2em Arial, Helvetica, sans-serif;
  background: $gray-darker;
}

body {
  overflow-x: hidden;
  height: auto !important;
  min-height: 100%;
}

.page-container {
  background-color: $gray-lightest;
  min-height: 100vh;
}

#js-react-app-routes {
  max-width: 80rem;
  margin: auto;
}

// Z-Index Stacking Order (match with JS constants CONTENT_LAYER)
.mobi__z-stack-bottom {
  position: relative;
  z-index: 1;
}

.mobi__z-stack-content {
  position: relative;
  z-index: 2;
  background-color: #fff;
}

#main-container {
  position: relative;
}
// />

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px !important;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

@import 'base/legacy-globals';

.nobullet {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

// Hide the overflow for all IE browsers that support SVG.
// Source: https://github.com/necolas/normalize.css#svgnotroot
svg:not(:root) {
  overflow: hidden;
}

.separator {
  height: 0.1rem;
}

@import 'base/helpers';
@import 'base/font-sizes';

@import 'modules/icons.legacy';

h2 span.tticon {
  width: auto;
  height: auto;
  margin-top: 0.1em;
  overflow: visible;
  text-indent: 0;
  text-shadow: none;
}

h2 .text {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 0;
  white-space: nowrap;
}

@import 'modules/investment-input-deposit';

// CONTENT
#content-container {
  padding: 0;
}

body#good-things {
  .page-container {
    background: $gray-lighter;
  }
}

@import 'modules/popup';
@import 'modules/info-box';
@import 'modules/loading-overlay';
@import 'modules/buttons.legacy';
@import 'modules/buttons.flat';
@import 'modules/button-4em';

// Used in Deposits
.confirmation {
  background-color: $gray-lighter;

  .confirmation-container {
    padding: 0.5em;
    max-width: 75em;
    margin: 0 auto;
  }
}

.button.flat.black {
  background-color: $gray-darkest !important;
  background: $gray-darkest !important;
  border: 1px $gray-darkest solid !important;
  color: $gray-lightest;
}

@import 'modules/button-group';

.checkbox-text {
  font-weight: bold;
  font-size: 1.2em;
  vertical-align: top;
}

ol.nostyle {
  list-style: none;
  padding: 0;
}

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@import 'modules/all-up';
@import 'modules/faux-radio';
@import 'modules/black-flat';

// disable the 'X' icon/button that appears for input type=search textboxes running on the chrome browser
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.giddyup {
  background-color: #00509f;
  box-shadow: 0 0 1px rgba($gray-darkest, 0.1);
}

@import 'modules/chart-tacho';
@import 'rwwaCss/core';

.invisible {
  display: none;
}
